<script setup>
import { ref } from "vue";
import logoutSVG from "@/assets/svg/logout-box-line.svg";

const props = defineProps({
  GAME: { type: String, required: true },
  CONNECTED: { type: Boolean, required: true },
  JOINED: { type: Boolean, required: true },
  TEAM: { type: String, required: true },
  NAME: { type: String, required: true },
});

const showModal = ref(false);
const emit = defineEmits(["change-team"]);

function openModal() {
  showModal.value = true;
}
function closeModal() {
  showModal.value = false;
}
function logout() {
  closeModal();
  emit("change-team");
}
</script>

<template>
  <footer class="status-bar level mb-0">
    <div class="level-left">
      <div class="level-item"><p>You are playing Life Goes On.</p></div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <div>
          <a
            class="has-text-light pr-1"
            href="https://www.machinaex.com/de/kontakt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressum & Datenschutz
          </a>
        </div>
        <div class="status-game-info">
          <p>
            Game ID:
            <strong :class="[props.JOINED ? 'has-text-white' : 'has-text-warning']">
              {{ props.JOINED ? TEAM : "-" }}
            </strong>
          </p>
          <p class="pl-2">
            Status:
            <strong
              class="is-uppercase"
              :class="[props.CONNECTED ? 'has-text-success' : 'has-text-danger']"
            >
              {{ props.CONNECTED ? `connected` : "no connection" }}
            </strong>
          </p>
        </div>
        <p class="pl-2">
          <a class="has-text-white is-uppercase is-underlined" @click="openModal">
            <img class="image is-24x24" :src="logoutSVG" alt="Einstellungen" />
          </a>
        </p>
      </div>
    </div>
  </footer>
  <div v-if="showModal" class="modal is-active" style="z-index: 100">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content">
      <div class="card has-background-grey-darker">
        <header class="card-header">
          <h4
            class="is-size-4 card-header-title has-text-white is-justify-content-center"
          >
            Willst du dich wirklich aus diesem Spiel ausloggen?
          </h4>
        </header>
        <!-- <div class="card-content has-text-white"></div> -->
        <footer class="card-footer">
          <div class="card-footer-item">
            <button class="button is-warning" @click="logout">Logout</button>
          </div>
          <div class="card-footer-item">
            <button class="button" @click="closeModal">Weiterspielen</button>
          </div>
        </footer>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal"
    ></button>
  </div>
</template>
