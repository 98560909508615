<script setup>
import { ref, computed, inject } from "vue";
import { hasKey, getLocalValueOrDefault } from "@/utils";
import io from "socket.io-client";
import { AudioPlayer } from "@/utils/player";
import StatusBar from "@/components/StatusBar.vue";
import ZeisigContainer from "./components/ZeisigContainer.vue";
import LoginOverlay from "@/components/LoginOverlay.vue";
import WaitOverlay from "@/components/WaitOverlay.vue";

const defaultConfig = inject("globalAppConfig");
const { DEVICE, GAME, BASE_URL } = defaultConfig;

const loginOverlay = ref(undefined);

const CONNECTED = ref(false); // socket connection established
const TERMINAL = ref(["..."]);
const NAME = ref(getLocalValueOrDefault("NAME", defaultConfig?.NAME || ""));
const TEAM = ref(getLocalValueOrDefault("TEAM", defaultConfig?.TEAM || ""));
const INDEX = ref(getLocalValueOrDefault("INDEX", defaultConfig?.INDEX || "0"));
const OLDNAME = ref("");
const OLDTEAM = ref("");
const JOINED = ref(false); // successfully joined a team session
const ZEISIG = ref("LOCK");
const localZeisigLocked = getLocalValueOrDefault("ZEISIG_LOCKED", "true");
const ZEISIG_LOCKED = ref(localZeisigLocked === "true" ? true : false);
const backgroundImage = computed(() => {
  let image;
  const index = Number(INDEX.value) % 4;
  switch (index) {
    case 0:
      image = require("@/assets/img/mini-bg-grau.jpg");
      break;
    case 1:
      image = require("@/assets/img/mini-bg-leo.jpg");
      break;
    case 2:
      image = require("@/assets/img/mini-bg-palmen.jpg");
      break;
    case 3:
      image = require("@/assets/img/mini-bg-ski.jpg");
      break;
  }
  return image;
});
////////////////////////////////////////////////////////////////////////////////
//////////////////////////// AUDIO PLAYER STUFF ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const player = new AudioPlayer();
const INTERACTION_NEED = ref(false);

player.on("playerror", (trackIndex, error) => {
  if (error.includes("playback was not within a user interaction"))
    INTERACTION_NEED.value = true;
});

player.on("onloaderror", (trackIndex, error) => {
  console.error(error);
});

player.on("onunlock", () => {
  INTERACTION_NEED.value = false;
});

player.on("play", () => {
  socket.emit("zeisig", { event: "START_PLAYING" });
});

player.on("load", () => {
  socket.emit("zeisig", { event: "DONE_LOADING" });
});

player.on("end", (trackIndex) => {
  socket.emit("zeisig", {
    event: "DONE_PLAYING",
    file: player.tracklist?.[trackIndex]?.file,
  });
});

player.on("stop", (trackIndex) => {
  socket.emit("zeisig", {
    event: "STOP_PLAYING",
    file: player.tracklist?.[trackIndex]?.file,
  });
});

////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// SOCKET STUFF ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
const socket = io(`${BASE_URL}/game/${GAME}/livingroom/${DEVICE}`);

socket.on("connect", () => {
  // console.log("connected to game socket");
  CONNECTED.value = socket.connected;
  if (NAME.value !== "" && TEAM.value !== "") {
    const data = { team: TEAM.value, player: NAME.value };
    joinTeam(data);
  }
});

socket.on("connect_error", (err) => {
  CONNECTED.value = socket.connected;
  console.log(`connect_error due to ${err.message}`);
});

socket.on("disconnect", (reason) => {
  CONNECTED.value = socket.connected;
  console.log(`disconnect due to ${reason}`);
  // the disconnection was initiated by the server, we need to reconnect manually
  if (reason === "io server disconnect") {
    // resetTeam();
    socket.connect();
  }
});

socket.on("error", (error) => {
  if (hasKey(error, "name")) {
    console.error("Socket error message", error.name, error?.message);
    if (error.name === "NotFoundError") {
      resetTeam();
    }
  }
});

socket.on("playerRemoved", () => resetTeam());

socket.on("status", (data) => {
  console.log(`Sockte status:`, data);
  JOINED.value = true;
  const now = Date.now();
  if (hasKey(data, "loadAudio")) {
    if (hasKey(data.loadAudio, "payload.files")) {
      player.load(data.loadAudio.payload.files);
    }
  }
  if (hasKey(data, "playAudio")) {
    if (
      now - data.playAudio.timestamp < 180000 &&
      hasKey(data.playAudio, "payload.file")
    )
      player.play(
        data.playAudio.payload.file,
        data.playAudio.timestamp,
        data.playAudio.retryOnError
      );
  }
  if (hasKey(data, "control.terminal")) {
    let terminalText = data.control.terminal;
    if (typeof terminalText === "string" || terminalText instanceof String) {
      terminalText = [terminalText];
    }
    TERMINAL.value = terminalText;
  }
  if (hasKey(data, "control.zeisig")) {
    ZEISIG.value = data.control.zeisig;
  }
  if (hasKey(data, "index")) {
    INDEX.value = data.index;
  }
});

socket.on("control", (data) => {
  console.log(`Socket control:`, data);
  if (hasKey(data, "payload.terminal")) {
    let terminalText = data.payload.terminal;
    if (typeof terminalText === "string" || terminalText instanceof String) {
      terminalText = [terminalText];
    }
    TERMINAL.value = terminalText;
  }
  if (hasKey(data, "payload.zeisig")) {
    ZEISIG.value = data.payload.zeisig;
  }
});

socket.onAny((event, ...args) => {
  const knownEvents = ["status", "playAudio", "loadAudio", "stopAudio", "control"];
  if (!knownEvents.includes(event)) {
    console.log(`Socket onAny: ${event}`, ...args);
  }
});

socket.on("playAudio", (data) => {
  if (hasKey(data, "payload.file")) {
    player.play(data.payload.file, data.timestamp, data.retryOnError);
  }
});

socket.on("loadAudio", (data) => {
  if (hasKey(data, "payload.files")) {
    player.load(data.payload.files);
  }
});

socket.on("stopAudio", (data) => {
  if (hasKey(data, "payload.file")) {
    player.stop();
  }
});

function resetTeam() {
  JOINED.value = false;
  OLDTEAM.value = TEAM.value;
  OLDNAME.value = NAME.value;
  TEAM.value = "";
  localStorage.removeItem("TEAM");
  NAME.value = "";
  localStorage.removeItem("NAME");
  INDEX.value = "0";
  localStorage.removeItem("INDEX");
  ZEISIG_LOCKED.value = true;
  localStorage.setItem("ZEISIG_LOCKED", "true");
}

function joinTeam(data) {
  // console.log("join team connect with data:", data);
  NAME.value = data.player;
  localStorage.setItem("NAME", data.player);
  TEAM.value = data.team;
  localStorage.setItem("TEAM", data.team);
  INDEX.value = data?.index || "0";
  localStorage.setItem("INDEX", data.index);
  socket.emit("joinTeam", { team: data.team, player: data.player });
}

function unlockZeisig() {
  ZEISIG_LOCKED.value = false;
  localStorage.setItem("ZEISIG_LOCKED", "false");
  // console.log(ZEISIG_LOCKED.value);
}

function playTestSound() {
  player.play(0);
}
</script>

<template>
  <main :style="{ backgroundImage: `url(${backgroundImage})` }">
    <ZeisigContainer
      v-bind="{ CONNECTED, JOINED, TEAM, NAME, ZEISIG, INTERACTION_NEED, TERMINAL }"
    />
  </main>
  <StatusBar
    v-bind="{ CONNECTED, JOINED, TEAM, NAME, GAME }"
    @change-team="resetTeam"
  />
  <LoginOverlay
    v-if="CONNECTED && !JOINED"
    ref="loginOverlay"
    v-bind="{ BASE_URL, GAME, OLDTEAM, OLDNAME }"
    @play-test-sound="playTestSound"
    @join-team="joinTeam"
  />
  <Transition name="fade-out">
    <WaitOverlay
      v-if="CONNECTED && JOINED && ZEISIG_LOCKED"
      v-bind="{ NAME, TEAM, ZEISIG, ZEISIG_LOCKED }"
      @play-test-sound="playTestSound"
      @unlock-zeisig="unlockZeisig"
    />
  </Transition>
</template>
