<script setup>
import { ref, watchEffect } from "vue";

const props = defineProps({
  BASE_URL: { type: String, required: true },
  GAME: { type: String, required: true },
  OLDTEAM: { type: String, required: true },
  OLDNAME: { type: String, required: true },
});
const emit = defineEmits(["join-team", "play-test-sound"]);
defineExpose({ reset });

const isActive = ref(true);
const isLoading = ref(false);
const player = ref("");
const team = ref("");
const limit = ref(4);
const errors = ref({ duplicate: false, limit: false, unknown: false });

function reset() {
  isActive.value = true;
  isLoading.value = false;
  player.value = "";
  team.value = "";
}

function resetAfterDuplicate() {
  player.value = "";
  errors.value.duplicate = false;
}

function createNewPlayer() {
  isLoading.value = true;
  fetch(`${props.BASE_URL}/${props.GAME}/livingroom/team/${team.value}/player`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ player: player.value.trim() }),
  })
    .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
    .then(({ status, body }) => {
      console.log("got data from adaptor for new player", status, body);
      if (status === 404) {
        errors.value.unknown = true;
        isLoading.value = false;
      } else if (status === 400 && body.name == "LimitReachedError") {
        errors.value.limit = true;
        limit.value = body?.limit || 4;
        isLoading.value = false;
      } else if (status === 400 && body.name == "DuplicateError") {
        errors.value.duplicate = true;
        isLoading.value = false;
      } else {
        joinTeam();
      }
    })
    .catch((error) => {
      isLoading.value = false;
      console.error(error);
    });
}

function joinTeam() {
  isLoading.value = true;
  fetch(
    `${props.BASE_URL}/${props.GAME}/livingroom/team/${
      team.value
    }/player/${player.value.trim()}`
  )
    .then((r) =>
      r.json().then((playerData) => {
        console.log("got player data", playerData);
        emit("join-team", {
          team: team.value,
          player: playerData.name,
          index: playerData?.index || 0,
        });
        isLoading.value = false;
      })
    )
    .catch((error) => {
      isLoading.value = false;
      console.error(error);
    });
}

let testSoundCounter = 0;
function testSound() {
  testSoundCounter++;
  if (testSoundCounter % 3 == 0) emit("play-test-sound");
}

watchEffect(() => {
  player.value = player.value.trim();
  if (player.value.length > 9) {
    player.value = player.value.slice(0, 10);
  }
});
</script>

<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card has-background-grey-darker">
        <header class="card-header">
          <h4 class="is-size-4 card-header-title has-text-white" @click="testSound">
            Einem Spiel beitreten
          </h4>
        </header>
        <div class="card-content has-text-white">
          <div class="content">
            <div class="field">
              <label class="label has-text-white">Spiel Code</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  :placeholder="props?.OLDTEAM || '5-stelliger Code'"
                  :value="team"
                  @input="
                    (evt) => {
                      team = evt.target.value;
                      errors.unknown = false;
                      errors.duplicate = false;
                    }
                  "
                  :disabled="isLoading"
                />
              </div>
              <p v-if="errors.unknown" class="help has-text-danger">
                Ungültiger Zugangscode. Das Spiel mit dem Code
                <i>{{ team }}</i>
                konnte nicht gefunden werden.
                <br />
                Der Code hat 5 Ziffern.
              </p>
            </div>
            <div class="field">
              <label class="label has-text-white">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  :placeholder="props?.OLDNAME || 'Name'"
                  maxlength="10"
                  :value="player"
                  @input="
                    (evt) => {
                      player = evt.target.value;
                      errors.duplicate = false;
                    }
                  "
                  :disabled="isLoading"
                  @keyup.enter="createNewPlayer"
                />
              </div>
              <p v-if="player.length > 9" class="help has-text-warning">
                Maximal 10 Buchstaben erlaubt.
              </p>
              <p v-if="errors.duplicate" class="help has-text-warning">
                Eine Spieler:in mit dem Name
                <i>{{ player }}</i>
                nimmt bereits am Spiel teil.
                <br />
                Willst du
                <a class="is-underlined has-text-warning" @click="joinTeam">
                  wieder als
                  <i>{{ player }}</i>
                  beitreten
                </a>
                , oder
                <a
                  class="is-underlined has-text-warning"
                  @click="resetAfterDuplicate"
                >
                  dich mit einem anderen Namen anmelden
                </a>
                ?
              </p>
              <p v-if="errors.limit" class="help has-text-danger">
                Es sind bereits {{ limit }} Spieler:innen für das Spiel
                {{ team }} angemeldet.
                <br />
                Damit du am Spiel teilnehmen kannst, muss mindestens eine Spieler:in
                entfernt werden.
              </p>
            </div>
          </div>
        </div>
        <footer v-if="!errors.duplicate" class="card-footer">
          <div class="card-footer-item">
            <button
              class="button is-warning"
              :class="{ 'is-loading': isLoading }"
              @click="createNewPlayer"
              :disabled="!player || !team"
            >
              Beitreten
            </button>
          </div>
        </footer>
        <footer v-else class="card-footer">
          <div class="card-footer-item">
            <button class="button is-warning" @click="joinTeam">
              Als {{ player }} beitreten
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
