export function getLocalValueOrDefault(key, defaultValue) {
    const local = localStorage.getItem(key);
    return local ? local : defaultValue;
}


/**
 * checks if object has key. use dot notation for nested keys
 *
 * @param {Object}  object  object to check key
 * @param {String}  key     name of the key or nested key like: 'key.key2'
 */
export function hasKey(object, key) {
    if (!object || !key) return false;
    const keys = key.split('.');
    return hasNestedKeys(object, keys);
}

// helper for hasKey
function hasNestedKeys(object, keys) {
    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            if (index < keys.length - 1) object = object[key];
            else return true
        } else { return false; }
    }
}
