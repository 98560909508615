<script setup>
import LOGO from "@/assets/svg/zeisig_logo.svg";

const props = defineProps({
  TERMINAL: { type: Array, required: true },
  CONNECTED: { type: Boolean, required: true },
  JOINED: { type: Boolean, required: true },
  TEAM: { type: String, required: true },
  INTERACTION_NEED: { type: Boolean, required: true },
  NAME: { type: String, required: true },
  ZEISIG: { type: String, required: true },
});
let clickCounter = 0;
function ixClick() {
  if (props.INTERACTION_NEED) {
    clickCounter++;
    if (clickCounter % 2 == 0) {
      clickCounter = 0;
      window.location.reload();
    }
  }
}
</script>

<template>
  <div
    id="zeisig-container"
    :class="{ 'is-invisible': ZEISIG === 'HIDE', 'is-locked': ZEISIG === 'LOCK' }"
  >
    <img id="zeisig-logo" width="150" height="36" :src="LOGO" alt="ZEISIG" />
    <div id="zeisig-device">
      <section id="zeisig-top" class="is-flex-grow-1">
        <div id="name-label">
          {{ NAME }}
        </div>
      </section>
      <section
        class="is-flex is-justify-content-space-between is-align-items-center"
      >
        <div class="connected-container">
          <div
            :class="[
              'mr-2',
              JOINED && CONNECTED > 0
                ? 'is-indicator-connected'
                : 'is-indicator-warning',
            ]"
          ></div>
          <div class="pr-2">
            <p class="mini-led-label">Verbindungsstatus</p>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button class="button zeisig-display-button" @click="ixClick">
              <span :class="{ 'is-button-warning': INTERACTION_NEED }">
                {{ INTERACTION_NEED ? "♫" : "-" }}
              </span>
            </button>
          </div>
        </div>
      </section>
      <section class="is-flex">
        <div id="terminal-outer">
          <div v-if="INTERACTION_NEED" class="terminal-inner">
            <p class="row warning">
              Zeitdatei liegt vor. ♫ drücken, um Audio abzuspielen (oder diese
              Website neu laden).
            </p>
          </div>
          <div v-else class="terminal-inner">
            <p v-for="(text, index) in TERMINAL" :key="index" class="row">
              {{ text }}
            </p>
          </div>
        </div>
      </section>
      <!-- <section class="block field is-grouped">
        <p class="control">
          <button
            class="button is-light zeisig-button"
            :disabled="!INTERACTION_NEED"
          >
            Ready to Play
          </button>
        </p>
      </section> -->
    </div>
  </div>
</template>
