<script setup>
import musicSVG from "@/assets/svg/volume-up-line.svg";

defineProps({
  NAME: { type: String, required: true },
  TEAM: { type: String, required: true },
  ZEISIG: { type: String, required: true },
  ZEISIG_LOCKED: { type: Boolean, required: true },
});
console.log();
const emit = defineEmits(["unlock-zeisig", "play-test-sound"]);

function unlock() {
  emit("unlock-zeisig");
}

function testSound() {
  emit("play-test-sound");
}
</script>

<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card has-background-grey-darker">
        <div class="card-content has-text-white">
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <h4 class="block is-size-4 has-text-white">Hallo</h4>
            <section class="block player-list">
              <span class="is-impact-label is-size-5">
                {{ NAME }}
              </span>
            </section>
            <span class="block">
              du bist dem Spiel
              <i>{{ TEAM }}</i>
              beigetreten.
            </span>
            <section class="block is-flex is-align-items-center">
              <span class="pr-3">Sound testen:</span>
              <button class="button is-warning" @click="testSound">
                <span class="icon">
                  <img class="image is-24x24" :src="musicSVG" alt="play" />
                </span>
              </button>
            </section>
          </div>
        </div>
        <footer v-if="ZEISIG === 'UNLOCK' || ZEISIG === 'SHOW'" class="card-footer">
          <div class="card-footer-item">
            <button class="button is-warning" @click="unlock">
              ZeiSiG mini auspacken
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
